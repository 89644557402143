import React, { useContext } from "react";
import { Link } from "react-router-dom";

import TradingViewWidget, { Themes } from "react-tradingview-widget";

import Footer1 from "../layout/footer1";
import Header1 from "../layout/header1";
import Bottom from "./../element/bottom";
import mainPic from "../../images/futuresBg.jpg";
import PricingPlans from "./PricingPlans";
import { languages } from "../../languages";
import { LanguageContext } from "../../languagesContext";
function Homepage() {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = languages[language]
  return (
    <>
      <Header1 activeItem="home"/>

      <div className="intro" id="home">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="intro-content">
                <h1>
                  {selectedLanguage.landing.TradeWith}
                  <strong className="text-primary">Pmm Limited</strong>. <br />{" "}
                  {selectedLanguage.landing.TrustYearsTitle}
                </h1>
                <p>{selectedLanguage.landing.FastAndSecure}</p>
              </div>

              <div className="intro-btn">
                <Link to={"/signup"} className="btn btn-primary">
                  {selectedLanguage.aboutUs.button}
                </Link>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12">
              <img src={mainPic} alt="main" style={{ maxWidth: "600px" }} />
            </div>
          </div>
        </div>
      </div>

      <div className="price-grid section-padding" id="price">
        <div className="container">
          <div className="row" style={{ height: "500px" }}>
            <h2 className="text-center py-4">{selectedLanguage.landing.Charting}</h2>
            <TradingViewWidget
              symbol="NASDAQ:AAPL"
              theme={Themes.DARK}
              autosize
            />
          </div>
        </div>
      </div>

      <div className="getstart section-padding" id="portfolio">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="section-title">
                <h2>{selectedLanguage.landing.WhatToGet}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <div className="getstart-content">
                <span>
                  <i className="la la-user-plus"></i>
                </span>
                <h3>{selectedLanguage.landing.advancedToolsTitle}</h3>
                <p>
                    {selectedLanguage.landing.advancedToolsContent}
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <div className="getstart-content">
                <span>
                  <i className="la la-bank"></i>
                </span>
                <h3>{selectedLanguage.landing.analyticsTitle}</h3>
                <p>
                  {selectedLanguage.landing.analyticsContent}
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
              <div className="getstart-content">
                <span>
                  <i className="la la-exchange"></i>
                </span>
                <h3>{selectedLanguage.landing.educationalResourcesTitle}</h3>
                <p>
                  {selectedLanguage.landing.educationalResourcesContent}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio section-padding" id="portfolio">
        <div className="container">
          <div className="row py-lg-5 justify-content-center">
            <div className="col-xl-7">
              <div className="section-title text-center">
                <h2>{selectedLanguage.landing.createPortfolioTitle}</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-7 col-lg-6">
              <div className="portfolio_list">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex">
                      <span className="port-icon">
                        {" "}
                        <i className="la la-bar-chart"></i>
                      </span>
                      <div className="flex-grow-1">
                        <h4>{selectedLanguage.landing.powerfulTradingPlatformTitle}</h4>
                        <p>
                            {selectedLanguage.landing.powerfulTradingPlatformContent}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="d-flex">
                      <span className="port-icon">
                        {" "}
                        <i className="la la-lock"></i>
                      </span>
                      <div className="flex-grow-1">
                        <h4>{selectedLanguage.landing.unlimitedWithdrawalsTitle}</h4>
                        <p>
                          {selectedLanguage.landing.unlimitedWithdrawalsContent}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex">
                      <span className="port-icon">
                        {" "}
                        <i className="la la-mobile"></i>
                      </span>
                      <div className="flex-grow-1">
                        <h4>{selectedLanguage.landing.supportTitle}</h4>
                        <p>
                            {selectedLanguage.landing.supportContent}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex">
                      <span className="port-icon">
                        <i className="la la-calendar-check-o"></i>
                      </span>
                      <div className="flex-grow-1">
                        <h4>{selectedLanguage.landing.joinCommunityTitle}</h4>
                        <p>
                            {selectedLanguage.landing.joinCommunityContent}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6">
              <div className="portfolio_img">
                <img
                  src={require("./../../images/portfolio.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="intro-btn">
            <Link to={"/signup"} className="btn btn-primary">
              {selectedLanguage.landing.JoinNow}
            </Link>
          </div>
        </div>
      </div>

      <PricingPlans />

      <Bottom />

      <Footer1 />
    </>
  );
}

export default Homepage;
