import React, { useContext } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { languages } from "../../languages";
import { LanguageContext } from "../../languagesContext";
const Header1 = ({ activeItem }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const selectedLanguage = languages[language];
  const markets = [
    {
      name: selectedLanguage.landing.Forex,
      navigate: "/markets/forex",
    },
    {
      name: selectedLanguage.landing.Crypto,
      navigate: "/markets/crypto",
    },
    {
      name: selectedLanguage.landing.Stocks,
      navigate: "/markets/stocks",
    },
    {
      name: selectedLanguage.landing.CFDs,
      navigate: "/markets/cfds",
    },
    {
      name: selectedLanguage.landing.Futures,
      navigate: "/markets/futures",
    },
    {
      name: selectedLanguage.landing.Commodities,
      navigate: "/markets/commodities",
    },
  ];
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="navigation">
                <Navbar bg="light" expand="lg">
                  <Link className="navbar-brand" to={"/"}>
                    <img src={require("./../../images/logo.png")} alt="" />
                  </Link>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="ms-auto">
                      <Nav.Item>
                        <Link
                          className={`nav-Nav.link ${
                            activeItem === "home" && "active"
                          }`}
                          to="/"
                        >
                          {selectedLanguage.landing.Home}
                        </Link>
                      </Nav.Item>
                      <NavDropdown title="Markets" id="basic-nav-dropdown">
                        {markets.map((market) => (
                          <div key={market.name}>
                            <Link to={market.navigate}>{market.name}</Link>
                          </div>
                        ))}
                      </NavDropdown>
                      <Nav.Item>
                        <Link
                          className={`nav-Nav.link ${
                            activeItem === "about-us" && "active"
                          }`}
                          to="/about-us"
                        >
                          {selectedLanguage.rest.AboutUs}
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link
                          className={`nav-Nav.link ${
                            activeItem === "pricing" && "active"
                          }`}
                          to="/pricing"
                        >
                          {selectedLanguage.landing.AccountTypesTitle}
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link
                          className={`nav-Nav.link ${
                            activeItem === "signup" && "active"
                          }`}
                          to="/signup"
                        >
                          {selectedLanguage.rest.Register}
                        </Link>
                      </Nav.Item>
                  <div className="signin-btn">
                    <Link className="btn btn-primary ms-3" to={"/signin"}>
                      {selectedLanguage.rest.SignIn}
                    </Link>
                  </div>
                <div className="d-flex gap-1 " style={{marginLeft:'5px'}}>
                  <div className="cursor-pointer" onClick={()=>setLanguage("French")}>
                  <svg
                    viewBox="0 0 36 36"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--twemoji"
                    preserveAspectRatio="xMidYMid meet"
                    fill="#000000"
                    width='30'
                    height='30'
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        fill="#ED2939"
                        d="M36 27a4 4 0 0 1-4 4h-8V5h8a4 4 0 0 1 4 4v18z"
                      ></path>
                      <path
                        fill="#002495"
                        d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5H4z"
                      ></path>
                      <path fill="#EEE" d="M12 5h12v26H12z"></path>
                    </g>
                  </svg>
                  </div>
                  <div className="cursor-pointer" onClick={()=>setLanguage("English")}>

                  <svg
                    viewBox="0 0 36 36"
                    xmlns="http://www.w3.org/2000/svg"
                    width='30'
                    height='30'
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--twemoji"
                    preserveAspectRatio="xMidYMid meet"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        fill="#00247D"
                        d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z"
                      ></path>
                      <path
                        fill="#CF1B2B"
                        d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z"
                      ></path>
                      <path
                        fill="#EEE"
                        d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z"
                      ></path>
                      <path
                        fill="#CF1B2B"
                        d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z"
                      ></path>
                    </g>
                  </svg>
                  </div>
                </div>
                    </Nav>
                  </Navbar.Collapse>

                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header1;
