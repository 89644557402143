import React, { useContext } from "react";
import Bottom from "../element/bottom";
import Header1 from "../layout/header1";
import pic from "../../images/commoditiesBg.jpg";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../languagesContext";
import { languages } from "../../languages";
const AboutUs = () => {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = languages[language];
  return (
    <div>
      <Header1 activeItem="about-us"></Header1>
      <div className="intro" id="home">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="intro-content">
                <h1>{selectedLanguage.aboutUs.header}</h1>
                <p>{selectedLanguage.aboutUs.content}</p>
              </div>

              <div className="intro-btn">
                <Link to={"/signup"} className="btn btn-primary">
                  Get Started
                </Link>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12">
              <img src={pic} alt="main" style={{ maxWidth: "600px" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="intro" id="home">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12">
              <h1>{selectedLanguage.aboutUs.securityTitle}</h1>
              <p>{selectedLanguage.aboutUs.securityContent}</p>
            </div>
            <div className="col-12">
              <h1>{selectedLanguage.aboutUs.regulationTitle}</h1>
              <p>{selectedLanguage.aboutUs.regulationContent}</p>
            </div>
            <div className="col-12">
              <h1>{selectedLanguage.aboutUs.leverageTitle}</h1>
              {selectedLanguage.aboutUs.leverageContent.map((content,index)=>(
                <p key={index}>{content}</p>
              ))}
            </div>
            <div className="col-12">
              <h1>{selectedLanguage.aboutUs.refundTitle}</h1>
              {selectedLanguage.aboutUs.refundContent.map((content,index) =>(
                <p key={index}>{content}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Bottom />
    </div>
  );
};
export default AboutUs;
