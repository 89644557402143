import React, { useContext } from "react";
import Bottom from "../element/bottom";
import Header1 from "../layout/header1";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import forexBg from "../../images/forexBg.jpg";
import { LanguageContext } from "../../languagesContext";
import { languages } from "../../languages";
const Markets = () => {
  const location = useLocation();
  const { language } = useContext(LanguageContext);
  const selectedLanguage = languages[language];
  const markets = [];
  const allMarkets = selectedLanguage.markets;
  for (const marketKey in allMarkets) {
    const item = allMarkets[marketKey];
    const newItem = {
      name: item.name,
      navigate: item.navigate,
      heading: item.heading,
      mainInfo: item.mainInfo,
      description: item.description,
    };
    markets.push(newItem);
  }
  const selectedMarket = markets.find(
    (market) => market.navigate === location.pathname
  );
  return (
    <div>
      <Header1 activeItem="markets" />
      <div className="intro">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="intro-content">
                <h1>
                  {selectedLanguage.rest.GetInto}
                  <strong className="text-primary">
                    {" " + selectedMarket.name} {selectedLanguage.rest.Trading}
                  </strong>
                </h1>
                <p>{selectedMarket.mainInfo}</p>
              </div>

              <div className="intro-btn"></div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12">
              <img src={forexBg} alt="main" style={{ maxWidth: "600px" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="intro">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12">
              <h2>{selectedMarket.heading}</h2>
              {selectedMarket.description.map((text) => (
                <p key={text}>{text}</p>
              ))}
            </div>
          </div>
          <Link to="/signup" className="btn btn-primary">
            {selectedLanguage.landing.JoinNow}
          </Link>
        </div>
      </div>
      <Bottom />
    </div>
  );
};
export default Markets;
